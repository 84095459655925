require('cleave.js');
require('./class/Ui');

const verify_form = oid('verification-form');
const code_inputs = document.getElementsByClassName('validation-code');
const code_wrapper = oid('validation-code-wrapper');
const validation_button = oid('validation-button');
const max_length_code = 6;
let initial_code_sent = false;

if (verify_form) {
    window.onload = () => {
        if (!document.hidden) {
            sendVerificationCode();
        }
    }

    addEventListener('visibilitychange', () => {
        if (!initial_code_sent && !document.hidden) {
            sendVerificationCode();
        }
    });

    let pasting = false;

    if (code_inputs.length) {
        code_inputs[0].focus();
        code_inputs[0].addEventListener('paste', e => {
            pasting = true;
            e.preventDefault();
            let paste = (event.clipboardData || window.clipboardData).getData('text');
            console.log("paste", paste);
            let i;
            for (i = 0; i < Math.min(paste.length, code_inputs.length); i++) {
                code_inputs[i].value = paste[i].replace(/[^0-9]/g, '');
            }

            if (i === max_length_code) {
                verifyCode();
            }

            setTimeout(() => {
                pasting = false;
                focusNextChar(i - 1);
            }, 200);
        });

        Array.from(code_inputs).forEach(code => {

            code.addEventListener('keydown', function (e) {
                if (code.value.length >= max_length_code && e.code !== "Backspace") {
                    e.preventDefault();
                }

                const charId = code.getAttribute('data-char');
                if ((e.code === "Backspace" && !isTextSelected(code)) || e.code === "ArrowLeft") {
                    e.preventDefault();
                    focusPrevChar(charId);
                } else if (e.code === "ArrowRight") {
                    e.preventDefault();
                    focusNextChar(charId);
                }
            });

            let oVal;
            code.addEventListener('keypress', function (e) {

                if (e.code !== "Backspace" && isNaN(e.key)) {
                    console.debug("not a number", e.key, pasting);
                    e.preventDefault();
                }

                code_wrapper.classList.remove('input-error');
                code_wrapper.classList.remove('shake');

                const charId = parseInt(code.getAttribute('data-char'));
                this.value = getValidEventCode(e);
                if (this.value.length) {
                    if (charId < max_length_code - 1 && code.value.length) {
                        // Valeur existante
                        focusNextChar(charId);
                    } else if (!isTextSelected(code)) {
                        verifyCode();
                    }
                } else if (e.keyCode === 8 && this.value.length === 0) {
                    e.preventDefault();
                    focusPrevChar(charId);
                }

                oVal = code.value;
            });
        });
    }

    verify_form.addEventListener('submit', function (e) {
        e.preventDefault();
        console.log("verify (submit)")
        verifyCode();
    });
}

function sendVerificationCode() {
    return ajx(baseurl + "verification-code", "POST").then(data => {
        if (data && data.hasOwnProperty('status')) {
            if (data.status === "success") {
                onCodeSuccess();
            } else if (data.status === "error") {
                onCodeError();
            }
        }
        if (data && data.hasOwnProperty('error')) {
            handleJsonResp(data);
            onCodeError();
        }
    }).finally(() => {
        initial_code_sent = true;
    });
}

function getFullCode() {
    let fullCode = "";
    Array.from(code_inputs).forEach(code => {
        fullCode += code.value;
    });

    return fullCode;
}

let prevCode;

function verifyCode() {
    const fullCode = getFullCode();
    if (prevCode === fullCode) {
        console.debug("skip same request", prevCode)
        onCodeError();
        return false;
    }

    prevCode = fullCode;

    Array.from(code_inputs).forEach(code => {
        code.classList.add('disabled');
    });

    validation_button.classList.add('loading');

    return ajx(verify_form.action, 'POST', {
        code: fullCode
    })
        .then(response => JSON.parse(response))
        .then(data => {
            if (data && data.hasOwnProperty('status')) {
                if (data.status === "success") {
                    onCodeSuccess();
                } else if (data.status === "error") {
                    onCodeError();
                }
            }
            if (data && data.hasOwnProperty('error')) {
                handleJsonResp(response);
                onCodeError();
            }
        })
        .catch(response => {
            if (response.length) {
                handleJsonResp(response);
            }
            onCodeError();
        });
}

function getValidEventCode(e) {
    if ((e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 65 && e.keyCode <= 90) || (e.keyCode >= 97 && e.keyCode <= 122)) {
        e.preventDefault();
        var keyCode = e.keyCode;
        if (keyCode >= 96 && keyCode <= 105) {
            // Numpad keys
            keyCode -= 48;
        }

        return String.fromCharCode(keyCode).replace(/[^0-9]/g, '');
    }

    return '';
}

function onCodeSuccess() {
    code_wrapper.classList.add('success');
    window.location.href = oid('redirect_url').value;
}

function onCodeError() {
    code_wrapper.classList.add('input-error');
    code_wrapper.classList.add('shake');

    Array.from(code_inputs).forEach(code => {
        code.value = "";
        code.classList.remove('disabled');
    });

    code_inputs[0].focus();

    setTimeout(() => {
        code_wrapper.classList.remove('shake');
        validation_button.classList.remove('loading');
    }, 800);
}


function focusNextChar(currentChar) {
    var nextChar = (parseInt(currentChar) + 1);
    var nextId = 'char_' + nextChar;
    if (oid(nextId)) {
        oid(nextId).focus();
        if (oid(nextId).value.length > 0) {
            oid(nextId).setSelectionRange(0, 9999);
        }
        return oid(nextId);
    }
    return null;
}

function focusPrevChar(currentChar) {
    if (parseInt(currentChar) >= 1) {
        var prevChar = (parseInt(currentChar) - 1);
        var prevId = 'char_' + prevChar;
        if (oid(prevId)) {
            oid(prevId).focus();
            oid(prevId).setSelectionRange(0, 9999); // select() ne fonctionne pas sur iOS
            return oid(prevId);
        }
    }
    return null;
}

function isTextSelected(input) {
    if (typeof input.selectionStart === "number") {
        return input.value.length > 0 && input.selectionStart === 0 && input.selectionEnd === input.value.length;
    } else if (typeof document.selection !== "undefined") {
        input.focus();
        return document.selection.createRange().text === input.value;
    }
}

const resend_form = oid('resend-form');
if (resend_form) {
    setFormPostListener(resend_form, (response) => {
        handleJsonResp(response);
        oid('verification-response').style.display = "inline-block";
        oid('verification-resend').style.display = "none";
        setTimeout(() => {
            oid('verification-response').style.display = "none";
            oid('verification-resend').style.display = "inline-block";
        }, 20000);
    });
}